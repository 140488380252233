.unselected{
    color: white;
    background-color: #DCE4F5;
    border: 0;
}
.unselected:hover{
    color: white;    
}

.card{
    border-radius: 10px !important;
    width: 400px;
    z-index: 9; 
    filter: drop-shadow(0px 4px 6px rgb(0,0,0,0.16));
}
.card-header{
    border-top-left-radius: 10px !important ;
    border-top-right-radius: 10px !important;
    background-color: #DCE4F5
}
.card-footer{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    background-color: #F5F6FC;
}