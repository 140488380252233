.sideMenu {
  backdrop-filter: blur(3px) brightness(0.5);
}
.sideMenu > div > div {
  width: 75%;
  margin-left: 25%;
}

.mainMenu {
  background-color: rgb(0, 0, 0, 0.1);
}
.mainMenu > div {
  margin: 0;
  height: 100%;
}
.mainMenu > div > div {
  width: 90%;
  height: 100%;
}

.mainMenu > div > div>div {
padding: 0;
}
  