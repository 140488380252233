.prices > div:nth-child(2) {
  margin-top: -70px;
}

.prices > div {
  width: 30%;
}

.prices {
    padding-top: 70px;
}
@media (max-width: 768px) {
  .prices div:nth-child(2) {
    margin-top: 0;
  }

  .prices > div {
    width: 100%;
  }
  .prices {
    padding-top: 0px;
  }
}

@media(min-width : 768px){  
  .h2{
    font-size: 50px;
  }
}