.icon {
  width: 20px;
  margin-right: 10px;
}
.item {
  padding-left: 5%;
  padding-right: 5%;
  display: block;
}
.active {
  background-color: rgb(94, 129, 244, 0.1);
}
.image {
  height: 50px;
}

@media (min-width: 992px) {
  .item {
    display: block;
  }
}

.fs-24{
  font-size: 24px;
}