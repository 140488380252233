.layoutLeft .largeDisplay {
  font-size: 90px;
}
.layoutLeft p {
  font-size: 25px;
}
.layoutLeft {
  border-radius: 50px;
}
.layoutLeft .layoutButton {
  font-size: 18px;
}

@media (max-width: 1500px) {
  .layoutLeft .largeDisplay {
    font-size: 45px;
  }
}

@media (max-width: 992px) {
  .layoutLeft .largeDisplay {
    font-size: 16px;
    font-weight: bolder;
  }
  .layoutLeft p {
    font-size: 12px;
  }

  .layoutLeft {
    border-radius: 15px;
  }

  .layoutLeft .layoutButton {
    font-size: 14px;
    font-weight: 600;
  }
}

.container {
  min-height: 100vh;
  background-color: #f5f6fc;
}

.container2 {
  min-height: 100vh;
  background-color: #f5f6fc;
}

.form {
  width: 68%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 20px rgb(0, 0, 0, 0.1));
}

.cover {
  height: 100vh;
  width: 100%;
}

.form2 {
  width: 68%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 20px rgb(0, 0, 0, 0.1));
}
.form input {
  border-color: #dce4f5;
}
.contentWidth {
  width: 68%;
}
@media (max-width: 992px) {
  .container {
    min-height: calc(100vh - 82px);
  }
  .contentWidth {
    width: 68%;
  }
  .form {
    width: 100%;
    background-color: transparent;
    padding: 0px;
    filter: none;
  }

  .form2 {
    width: 100%;
    margin-top: -350px;
    background-color: transparent;
    padding: 0px;
    filter: none;
  }

  .cover {
    height: 60vh;
    width: 100%;
  }
}

.stripeInput {
  padding: 0.85rem 0.75rem;
  font-size: 1rem;
  height: 48px;
  border-radius: 10px;
}

.payment h4 {
  font-size: 16px;
}
.payment label {
  font-size: 14px;
  color: #707070;
}
.payment input {
  border-radius: 10px;
  height: 48px;
}
.payment .paymentDetails,
.paymentSuccess {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 20px rgb(0, 0, 0, 0.1));
}
.payment .paymentType {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}
.payment .paymentPrice {
  font-size: 24px;
}
.payment .paymentSummary {
  font-size: 14px;
  color: #656e87;
}
.payment .storageSize {
  font-size: 20px;
}
.payment .paymentSuccess .paymentSuccessText {
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 992px) {
  .payment h4 {
    font-size: 24px;
  }
  .payment .paymentDetails,
  .paymentSuccess {
    width: 68%;
  }
  .payment .paymentType {
    font-size: 24px;
  }
  .payment .paymentPrice {
    font-size: 32px;
  }
  .payment .paymentSuccess .paymentSuccessText {
    font-size: 24px;
  }
}

.pricing .header,
.regHeader,
.loginHeader {
  font-size: 32px;
  font-weight: bolder;
}
.pricing .info {
  font-size: 16px;
  color: var(--dark-grey);
}
@media (max-width: 992px) {
  .pricing .header,
  .regHeader,
  .loginHeader {
    font-size: 24px;
  }
}

.fs-18 {
  font-size: 18px;
}

.emailHeader {
  font-size: 40px;
}
@media (max-width: 992px) {
  .emailHeader {
    font-size: 32px;
  }
  .emailInfo {
    color: var(--dark-grey);
  }
}

@media (max-width: 992px) {
  .container :global(.btn-primary) {
    color: white;
    background-color: var(--fadedBlue);
  }
}

.max-vh-100 {
  max-height: 100vh;
}

.custom_list {
  list-style-type: none;
  counter-reset: list-counter;
}

.custom_list li {
  position: relative;
  counter-increment: list-counter;
  padding-left: 2em;
  margin-bottom: 0.5em;
}
.custom_list li::before {
  content: "(" counter(list-counter, lower-roman) ")";
  position: absolute;
  left: 0;
  font-family: Arial, sans-serif;
}
/* @media (min-width: 768px) {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px; 
  }
}

@media (min-width: 300px) {
  table {
    width: 0%;
    border-collapse: collapse;
    margin-bottom: 20px; 
  }
} */

/* th,
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
}
 */

.custom_table tr td:nth-child(1) {
  width: 250px;
}
.custom_table tr td {
  border: none;
  padding-bottom: 20px;
}
.custom_table tr td p {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.recaptcha_unverified {
  border: 1px solid red;
}
