.active {
  color: var(--blue);
}

.search_card {
  border: none;
}
.single_border {
  border: 1px solid var(--light-blue);
}
.single_border_left {
  border-left: 1px solid var(--light-blue);
}
.search_card p {
  font-size: 16px;
}
@media (max-width: 768px) {
  .search_card {
    border: 1px solid var(--blue);
  }
  .search_card h4 {
    font-size: 16px;
  }
  .single_border {
    border: none;
  }
  .single_border_left {
    border: none;
  }
}

.group_card_active {
  color: white;
  background-color: var(--blue);
}

@media (min-width: 768px) {
  .banner {
    font-size: 50px;
  }
  .homeH2 {
    font-size: 40px;
    font-weight: 900;
  }
}

.search {
  width: 100%;
}
.search i,
.search input {
  font-size: 16px;
}
.search button {
  margin-right: -25px;
  z-index: 9 !important;
}
.search input {
  padding-left: 30px;
}
@media (min-width: 768px) {
  .search {
    width: 50%;
  }
}

.groupCard {
  font-size: 16px;
}
@media (max-width: 768px) {
  .groupCardHeader {
    font-size: 16px;
  }
}

.navSingle li {
  font-size: 14px;
}

.navSingle li a {
  font-size: 14px;
}

.faq-item * {
  font-size: 16px;
}
