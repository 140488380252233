.newFolderButton {
  width: 100%;
}

@media (min-width: 992px) {
  .newFolderButton {
    width: fit-content;
  }
  .table {
    flex: 2;
    overflow: auto;
  }
}

.image:not(:first-child) {
  margin-left: -10px;
}
.activity:first-child .image-line-up {
  visibility: hidden;
}
.activity:not(:first-child) .image-line-up {
  background-color: #dce4f5;
  color: #dce4f5;
  width: fit-content;
}
.activity:not(:last-child) .image-line-down {
  background-color: #dce4f5;
  color: #dce4f5;
  width: fit-content;
}
.activity:last-child .image-line-down {
  visibility: hidden;
}
.active {
  background-color: var(--blue);
  color: white !important;
}
.topMenu-border {
  border-bottom: 1px solid #ececf2;
}

.tabs {
  display: flex;
  color: #3a77f3;
  justify-content: space-between;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
}

.tabs li {
  border: 2px solid var(--blue);
  border-radius: 0px;
  width: 100%;
  padding: 0.5rem 0rem;
  text-align: center;
}

.tabs li.tabSelected {
  background-color: var(--blue);
  color: white;
}

.tabs li:first-child{
  display: none;
}