.prompt h5{
    font-size: 18px;
}

.prompt button{
    font-size: 18px;
}

.error{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.btnDisplay{
    display: flex;
    flex-direction: row;
    justify-content:end;
}
.btn {
    font-size: 18px;
    padding: 0;
    width: 45px;
    height: 40px;
    
}


@media (max-width:912px){
    .prompt h5{
        font-size: 15px;
    }

    .prompt button{
        font-size: 12px;
        margin-left: 10px;
    }

    .fileName{
        font-size: 20px;
        margin-top: 90px;
    }

    .error{
        height:100%;
    }

    .btn {
        font-size: 10px;
        width: 50px;
        height: 40px;
        padding: 0;
    }
    .fileNameDirection {
        display: flex;
        flex-direction: column;
    }
    .btnDisplay{
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 50px; 
    }
}

@media (max-width:540px){
    .fileNameDirection {
        display: flex;
        flex-direction: column;
    }
    .error{
        height:100%;
    }
    .btn {
        font-size: 18px;
        padding: 0;
        width: 40px;
        height: 40px;
        
    }
    .btnDisplay{
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        
    }
    .fileName{
        font-size: 10px;
        margin-top: 45px;

    }
    .prompt button{
        font-size: 10px;
        padding: 6px;
        width: 47px;
        height: 40px;
    }
    .prompt h5{
        font-size: 8px;
    }
    .btnDisplay{
        margin-top: 50px; 
    }
}

@media (max-width:280px){
    .error{
        
        height:100%;
        /* width: 340px; */
        width: 120vw;
    }   
    .btnDisplay{
       
        margin-top: 10px; 
    }
}