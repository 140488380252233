@import url("https://fonts.googleapis.com/css?family=Raleway");
.searchButton {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  margin-left: -50px;
}
.suggestion {
  color: var(--orange);
  background-color: #ffefe1;
  border-radius: 5px;
  font-size: 12px;
}
.suggestionTitle {
  font-size: 14px;
  color: #92929d;
}
.searchInput,
.searchInput:focus {
  border-radius: 15px;
  width: 100%;
  background-color: #e3eaf9;
}
@media (min-width: 992px) {
  .searchInput,
  .searchInput:focus {
    width: 50%;
    background-color: #fafafb;
  }
}

.accordionHeader {
  background-color: #ffefe1;
  padding-left: 1rem;
  border-radius: 10px;
}
.accordionHeader h4 {
  font-size: 16px;
}
.accordionBorder {
  border: 1px solid #ffbd80;
  border-radius: 10px;
}
.accordionBody {
  padding: 10px 0 0 20px;
  font-size: 14px;
}
@media (min-width: 992px) {
  .accordionHeader h4 {
    font-size: 20px;
  }
}

.main *,
.sideMenu * {
  font-family: "Raleway";
}

.main i,
.sideMenu i {
  font-family: "Font Awesome 5 Free";
}
.sideMenu {
  position: fixed;
  width: 270px;
  top: 0;
  overflow: auto;
  border-color: #f0f0f3;
}
.border-bottom {
  border-bottom: 1px solid #ececf2;
}
@media (min-width: 992px) {
  .main {
    width: calc(100% - 270px);
    margin-left: 270px;
  }
  .border-bottom {
    border-bottom: 0px solid #707070;
  }
}
