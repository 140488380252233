
.file{
    position: absolute;
    width: 30px;
    background-color: #FFF4E9;
    padding: 2%;
    border-radius: 10%;
    animation-duration: 5s;
    animation-name: movement;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.title{
    font-size: 14px;
    font-weight: 600;
}

@media (min-width: 576px) {
    .file{
        width: 50px;
    }
}

@media (min-width: 768px) {
    .file{
        width: 80px;    
    }
    .title{
        font-size: 32px;    
    }
}

@keyframes movement{
    0%{
        left:17%;
        top:0;
        z-index: 0;
    }
    50%{
        
        left:50%;
        top:50%;
        z-index: -1;
    }
    100%{
        
        left:73%;
        top:30%;
        z-index: 0;
    }
}