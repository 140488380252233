.nav {
  height: 100px;
  width: 100%;
}

.display_flex {
  display: flex;
}

.active {
  color: #ef3f3f;
}

.checkbox {
  display: none;
}

.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
  font-weight: bolder;
}

.layout-section {
  box-shadow: 0px 4px 30px rgb(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }
  .hamburger {
    display: block;
  }
  .nav {
    width: 65%;
    height: unset;
  }
  .nav img {
    height: 50px;
  }
  .layout-section {
    position: static;
    backdrop-filter: none;
    z-index: unset;
  }
}

.custom_table tr td:nth-child(1) {
  width: 250px;
}
.custom_table tr td {
  border: none;
  padding-bottom: 20px;
}
.custom_table tr td p {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
