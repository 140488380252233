tr.fileRow:focus {
  background-color: #f9fbff;
  outline-color: #3a77f3;
}
.image:not(:first-child) {
  margin-left: -10px;
}
.menu i,
.menu img {
  width: 20px;
}

@media (min-width: 992px) {
  .popup-content {
    margin: 0;
  }
  .border {
    border: 1px solid #ececf2;
  }
}

.removeYourself {
  filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 0.16));
  max-width: 80vw;
}

.removeYourself h4 {
  font-size: 16px;
}

.removeYourself p {
  font-size: 14px;
}

.removeYourself button {
  font-size: 18px;
}
@media (min-width: 992px) {
  .removeYourself h4 {
    font-size: 24px;
  }
  .removeYourself p {
    font-size: 20px;
  }
  .removeYourself button {
    font-size: 24px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.imgOverlap span:not(:first-child) {
  margin-left: -15px;
}

.horizontalAvatars {
  display: flex;
  flex-direction: row; /* this makes the avatars appear horizontally */
  align-items: center; /* you can adjust alignment as needed */
}

.file {
  font-size: 18px;
}

@media (max-width: 800px) {
  .file {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .file {
    font-size: 10px;
  }
}

@media (max-width: 300px) {
  .file {
    font-size: 8px;
  }
}

.file2 span:nth-child(2) {
  margin-left: 10px;
}

.file2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  /* background-color: yellow; */
  margin-bottom: 0;
}

@media (max-width: 800px) {
  .file2 {
    font-size: 11px;
    /* background-color: red; */
  }
}

@media (max-width: 400px) {
  .file2 {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    /* background-color: pink; */
  }
  .file2 span:nth-child(2) {
    margin-left: 0;
  }
}

@media (max-width: 300px) {
  .file2 {
    flex-direction: column;
    align-items: flex-start;
    font-size: 10px;
    /* background-color: pink; */
  }
  .file2 span:nth-child(2) {
    margin-left: 0;
  }
}
