.datePickerPopper {
  z-index: 99;
}
.datePickerPopper :global(.react-datepicker__triangle) {
  display: none;
}
.datePickerPopper :global(.react-datepicker__header) {
  background-color: transparent;
  border-bottom: none;
}

.datePickerPopper :global(.react-datepicker__current-month) {
  margin-bottom: 1rem;
  margin-top: 10px;
}
.datePickerPopper :global(.react-datepicker__navigation-icon) {
  margin-top: 1rem;
}
.datePickerPopper :global(.react-datepicker__navigation-icon::before, ) {
  border-color: #578cf8;
  top: 12px;
}

.datePickerPopper :global(.react-datepicker__day-name) {
  color: #9a9a9a;
}

.datePickerPopper :global(.react-datepicker__day--selected) {
  background-color: #f85757;
  border-radius: 50%;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.datePickerPopper :global(.react-datepicker__day-name),
.datePickerPopper :global(.react-datepicker__day),
.datePickerPopper :global(.react-datepicker__time-name) {
  width: 2rem;
  margin: 0.166rem 0rem;
  z-index: 99;
}

.datePickerWrapper input {
  font-size: 16px;
  padding-left: 0px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  width: 120px;
  height: 30px;
  outline: none;
  text-align: end;
  color: #578cf8;
  font-weight: 600;
}

.datePickerWrapper input::-webkit-input-placeholder {
  color: #578cf8 !important;
  font-size: 14px;
}
.datePickerWrapper input:-moz-placeholder {
  color: #578cf8 !important;
  font-size: 14px;
}
.datePickerWrapper input::-moz-placeholder {
  color: #578cf8 !important;
  font-size: 14px;
}
