.main {
  margin-top: 132px;
}
.all * {
  font-size: 24px;
}
@media (max-width: 768px) {
  .main {
    margin-top: 0px;
  }
  .all * {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .logo {
    margin-top: -70px;
  }
  .banner {
    /* font-size: 60px; */
    font-size: 42px;
  }
  .homeH2 {
    font-size: 50px;
    font-weight: 900;
  }
}

.aboutForm input,
.aboutForm textarea {
  font-size: 16px;
}

@media (min-width: 200px) {
  .video {
    width: 250px;
    height: 200px;
  }
}

@media (min-width: 300px) {
  .video {
    width: 500px;
    height: 200px;
  }
}

@media (min-width: 500px) {
  .video {
    width: 400px;
    height: 300px;
  }
}

@media (min-width: 700px) {
  .video {
    width: 710px;
    height: 400px;
  }
}

/* @media (min-width: 1200px) {
  .video {
    width: 600px;
    height: 400px;
  }
} */
