.card h4 {
  font-size: 32px;
}

.card p {
  font-size: 16px;
}
@media (max-width: 768px) {
  .card {
    background-color: var(--light-blue);
  }
  .card h4 {
    font-size: 24px;
  }
}

.img {
  max-height: 300px;
}
