@import url("https://fonts.googleapis.com/css?family=Raleway");
.sideMenu {
  position: fixed;
  width: 270px;
  top: 0;
  overflow: auto;
  z-index: 9;  
  border-color: #F0F0F3;
}

.aside {
  width: 335px;
  min-height: 100%;
  height: fit-content;
  border-color: #F0F0F3;
}
.mainElement *,.sideMenu *{
  font-family: "Raleway";
}

.mainElement i,.sideMenu i{
  font-family: "Font Awesome 5 Free";
}
.border-bottom {
  border-bottom: 1px solid #ececf2;
}
@media (min-width: 992px) {  
  .mainElement {
    width: calc(100% - 270px - 335px);    
    margin-left: 270px;
    min-height: 100%;    
    border-color: #F0F0F3;
  }
  .border-bottom {
    border-bottom: 0px solid #707070;
  }
}

.icon {
  width: 32px;
  height: 32px;
}
.imgOverlap div:not(:first-child) {
  margin-left: -15px;
}
.circular-button-plus {
  border: 1px dashed;
  height: 32px;
  width: 32px;
  padding: 0;
  margin-left: 10px;
  margin-left: -15px;
}

.container {
  overflow-x: scroll;
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container > *:not(:first-child) {
  margin-left: 10px;
}

.folder {
  min-width: 200px;
  width: 30%;
}
.folder.disabled{
  pointer-events: none;
  opacity: 0.4;
}

.file {
  min-width: 150px;
  width: 30%;
}
@media (min-width: 992px) {
  .file {
    min-width: unset;
    width: 24%;
  }
}


.oneFifth {
  flex: 0 0 auto;
  width: 20%;
  text-align: center;
}
.oneFifth img{
  filter: drop-shadow(0px 10px 25px rgb(0,0,0,0.05));
  
}
.emptyHeader{
  font-size: 24px;
  font-weight: 800;
}
.emptyInfo{
  font-size: 16px;  
  font-weight: 500;
}
.emptyDropText{
  font-size: 24px;
  font-weight: 500;
  color: #ACB8D1;
}
@media (min-width: 992px){
  .emptyHeader{
    font-size: 48px;
    font-weight: 700;
  }
  .emptyInfo{
    font-size: 24px;       
    font-weight: 600;
  }
}