.chat {
  border-radius: 18.5px 18.5px 18.5px 0;
  background-color: white;
}

.chatAuthor {
  border-radius: 17px 17px 0px 17px;
  background-color: #fda758;
  color: white;
}
.mobileHeight{
    height: 100%;
}
@media (max-width: 992px) {
  .mobileHeight {
      height: calc(100vh - 130px);
      padding-bottom: 55px;
  }
  .input{
    position: fixed;
    bottom: 25px;
    width: 85%;
  }
}
