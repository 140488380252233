@media (min-width: 992px) {
  .App {
    height: 100vh;
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .overflow-lg-auto {
    overflow: auto;
  }
}

/*Colors*/
.color-red {
  color: #ef3f3f;
}

.color-blue {
  color: #3a77f3;
}

.color-black {
  color: var(--color-black);
}

.color-dark-grey {
  color: var(--dark-grey);
}

.color-light-blue {
  color: var(--light-blue);
}

.color-orange {
  color: var(--orange);
}

.color-light-orange {
  color: var(--light-orange);
}

.color-violet {
  color: var(--violet);
}

.color-white {
  color: #ffffff;
}

/*Background colors*/
.bg-red {
  background-color: var(--red);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-black {
  background-color: black;
}

.bg-dark-grey {
  background-color: var(--dark-grey);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-orange,
.btn.btn-orange:hover {
  background-color: var(--orange);
}

.btn.bg-orange:hover {
  background-color: var(--orange);
}

.bg-light-orange {
  background-color: var(--light-orange);
}

.btn.bg-light-orange:hover {
  background-color: var(--light-orange);
}


.bg-violet {
  background-color: var(--violet);
}

.bg-white {
  background-color: white;
}

/*font sizes*/

.text-large {
  font-size: 24px;
}

@media (min-width: 1700px) {
  .text-large {
    font-size: 60px;
  }
}

.text-small {
  font-size: 14px;
}

@media (min-width: 1700px) {
  .text-small {
    font-size: 16px;
  }
}

.font-size-2x {
  font-size: 32px;
}

.font-size-xlarge {
  font-size: 50px;
}

.font-size-xxlarge {
  font-size: 60px;
}

.font-size {
  font-size: 16px;
}

/*font weights*/
.font-weight-medium {
  font-weight: 500;
}

.font-weight-extrabold {
  font-weight: 800;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight {
  font-weight: 400;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-light {
  font-weight: 300;
}

/*Buttons*/

.btn {
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  text-decoration: none;
}

.btn-radius-large {
  border-radius: 40px;
}

.btn-radius-10 {
  border-radius: 10px;
}

.btn-primary,
.btn-primary:hover {
  color: white;
  background-color: var(--blue);
}

.btn-faded-primary,
.btn-faded-primary:hover {
  color: white;
  background-color: var(--fadedBlue);
}

.btn-white,
.btn-white:hover {
  color: var(--blue);
  background-color: white;
}

.btn-disabled,
.btn-disabled:hover {
  background-color: #e1e1e1;
  color: #9d9d9d;
}

.btn-orange {
  color: black;
  background-color: var(--orange);
}

.btn-transparent {
  background-color: transparent;
  border: none;
}

.btn-shadow {
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}

.btn-outline-white,
.btn-outline-white:hover {
  border: 1px solid white;
  color: white;
}

.btn-primary-platform,
.btn-primary-platform:hover {
  color: white;
  background-color: #6497ff;
}

.btn-outline-primary,
.btn-outline-primary:hover {
  color: #3a77f3;
  border: 1px solid #3a77f3;
  background-color: transparent;
}

.layout-section {
  padding: 0 12.5%;
}

@media (max-width: 768px) {
  .layout-section {
    padding: 0 5%;
  }
}

/*Input elements*/
.form-control.orange {
  border-color: var(--orange);
}

.form-control.orange:active,
.form-control.orange:hover,
.form-control.orange:focus {
  box-shadow: 0px 4px 4px var(--orange);
}

.form-control.light-blue {
  border-color: #e3eaf9;
}

/*cards*/
.card-radius {
  border-radius: 15px;
}

.card-button-footer {
  border-bottom-left-radius: inherit;
  border-top-left-radius: 0;
  border-bottom-right-radius: inherit;
  border-top-right-radius: 0;
}

/*Bread crumbs*/
.breadcrumb-item-arrow+.breadcrumb-item-arrow::before {
  content: ">";
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

/*borders*/
.border-x {
  border-right: 1px solid #f0f0f3;
  border-left: 1px solid #f0f0f3;
}

.border-y {
  border-top: 1px solid #f0f0f3;
  border-bottom: 1px solid #f0f0f3;
}

.border-top {
  border-top: 1px solid #e3eaf9 !important;
}

.border-bottom {
  border-bottom: 1px solid #e3eaf9 !important;
}

.border {
  border: 1px solid #ececf2 !important;
}

/*Scroll*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #f5f6fc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f5f6fc;
}

.rounded {
  border-radius: 0.5rem !important;
}

.svgIcon {
  width: 25px;
  height: 25px;
}

@media (max-width: 768px) {
  .menuPopup-content {
    margin: 0 !important;
    overflow: auto;
    width: 100%;
  }
}

.sideMenu-content {
  margin: 0 !important;
  overflow: auto;
  width: 80%;
}

.modalPopup-overlay,
.sideMenu-overlay {
  background-color: rgb(0, 0, 0, 0.5);
}

.btn-primary-disabled {
  background-color: #dce4f5;
  color: white;
}

table {
  word-break: break-all;
}

th {
  word-break: keep-all;
}

label.btn-primary {
  border: 2px solid #3a77f3;
}

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}

.react-datepicker__input-container input:-moz-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}

.react-datepicker__input-container input::-moz-placeholder {
  color: #e8e8e8 !important;
  font-size: 14px;
}