@import url("https://fonts.googleapis.com/css?family=Raleway");

.sideMenu {
  position: fixed;
  width: 270px;
  top: 0;
  overflow: auto;

  border-color: #f0f0f3;
}
.border-bottom {
  border-bottom: 1px solid #ececf2;
}
@media (min-width: 992px) {
  .border-bottom {
    border-bottom: 0px solid #707070;
  }

  .main {
    width: calc(100% - 270px);
    margin-left: 270px;
  }
}

.main *,
.sideMenu * {
  font-family: "Raleway";
}

.main i,
.sideMenu i {
  font-family: "Font Awesome 5 Free";
}
