.alert {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  background-color: black;
  color: white;
  padding: 10px;
  display: flex;
}

.options {
  width: 20px;
  height: 20px;
}

.message {
  margin-left: 5px;
}
