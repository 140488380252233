.oneFifth {
  flex: 0 0 auto;
  width: 20%;
}
.oneFifth img {
  filter: drop-shadow(0px 10px 25px rgb(0, 0, 0, 0.05));
}

.selectedCard {
  filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 0.16));
}

.newFolderButton {
  width: 100%;
}

@media (min-width: 992px) {
  .newFolderButton {
    width: fit-content;
  }
  .table {
    flex: 2;
    overflow: auto;
  }
}

.active {
  background-color: var(--blue);
  color: white !important;
}

.emptyHeader {
  font-size: 24px;
  font-weight: bolder;
}
.emptyInfo {
  font-size: 16px;
}
@media (min-width: 992px) {
  .emptyHeader {
    font-size: 48px;
  }
  .emptyInfo {
    font-size: 24px;
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: #dce4f5;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 19px;
  height: 19px;
  background: #578cf8;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 19px;
  height: 19px;
  background: #578cf8;
  border-radius: 50%;
  cursor: pointer;
}

.bubble {
  color: #578cf8;
  padding: 4px 12px;
  position: absolute;
  top: 20px;
  border-radius: 4px;
  left: 50%;
  font-size: 14px;
  font-weight: 600;
  transform: translateX(-50%);
}


.tabs {
  display: flex;
  color: #3a77f3;
  justify-content: space-between;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
}

.tabs li {
  border: 2px solid var(--blue);
  border-radius: 0px;
  width: 100%;
  padding: 0.5rem 0rem;
  text-align: center;
}

.tabs li.tabSelected {
  background-color: var(--blue);
  color: white;
}

.tabs li:first-child{
  display: none;
}