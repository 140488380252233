@import url('https://fonts.googleapis.com/css?family=Poppins:thin,extra-light,light,regular,medium,semi-bold,bold,extra-bold,black');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,regular,medium,semi-bold,bold,extra-bold,black');
@import url('https://fonts.googleapis.com/css?family=Roboto:thin,extra-light,light,regular,medium,semi-bold,bold,extra-bold,black');
@import url('https://fonts.googleapis.com/css?family=Mulish:thin,extra-light,light,regular,medium,semi-bold,bold,extra-bold,black');
:root{
  --violet:#AC9CFF;
  --light-orange:#FFD3B3;
  --orange:#FDA758;
  --light-blue:#DCE4F5;
  --dark-grey:#656E87;
  --blue:#3A77F3;
  --red:#EF3F3F;
  --fadedBlue:#578CF8;
  --font-size:16px;
  --font-size-large:24px;
  --font-size-2x:32px;
  --font-size-xlarge:50px;
  --font-size-xxlarge:60px;
  --font-weight:400;
  --font-weight-medium:600;
  --font-weight-bold:700;
  --font-weight-extrabold:800;
}
body {
  margin: 0;
  font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

} 
h1,h2{
  font-size: 32px;
  font-weight: 700;
}
h5{
  font-size:16px;
  font-weight: 700;
}
h4{
  font-size:24px;
  font-weight: 700;
}
button{
  font-size:18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.openSans{
  font-family: "Open Sans";
}
.roboto{
  font-family: "Roboto";
}

#__react-alert__ div div div *{
  text-transform: lowercase;
  word-break: break-all;
}

#__react-alert__ div div div *::first-letter{
  text-transform: capitalize;
}