@media (max-width: 768px) {
  .mobile {
    background-color: #f2faff;
  }
  .mobile p {
    font-size: 14px;
  }
}

.small li a {
  font-size: 14px;
}

.custom_list {
  list-style-type: none;
  counter-reset: list-counter;
}

.custom_list li {
  position: relative;
  counter-increment: list-counter;
  padding-left: 2em;
  margin-bottom: 0.5em;
}
.custom_list li::before {
  content: "(" counter(list-counter, lower-roman) ")";
  position: absolute;
  left: 0;
  font-family: Arial, sans-serif;
}
