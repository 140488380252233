.team > div:nth-child(odd) {
  margin-top: -100px;
}

.team {
  padding-top: 100px;
}
.about_image {
  width: 75%;
}
@media (max-width: 768px) {
  .team > div:nth-child(odd) {
    margin-top: 0px;
  }

  .team {
    padding-top: 0px;
  }
  .about_image {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .quote {
    margin-right: -210px;
  }
  .banner {
    font-size: 60px;
  }
}
