.logoutStyle {
  font-size: 24px;
}

@media (max-width: 800px) {
  .logoutStyle {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .logoutStyle {
    font-size: 10px;
  }
}
