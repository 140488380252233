@import url("https://fonts.googleapis.com/css?family=Raleway");
@media (min-width: 1200px) {
  .profile,
  .notification,
  .invoices,
  .subscription {
    background-color: white;
    filter: drop-shadow(0px 3px 6px rgb(0, 0, 0, 0.08));
    padding: 1.5em;
    border-radius: 5px;
  }
  .invoices {
    padding: 0px;
  }
}
.changePassword {
  width: 95vw;
  background-color: white;
  padding: 1em;
}

@media (min-width: 1200px) {
  .changePassword {
    width: 35vw;
    max-height: 75vh;
    overflow: auto;
  }
}
.profile label,
.changePassword label,
.notification label,
.payment label {
  font-size: 14px;
  margin-bottom: 0;
  color: #707070;
}
.profile input,
.profile select,
.changePassword input,
.payment input,
.payment .stripeInput {
  border-radius: 10px;
  border: 1px solid #dddddd;
  background-color: white;
}
.stripeIcons {
  margin-left: -30px;
}
.profile button,
.changePassword button,
.notification button,
.subscription button,
.payment button {
  padding: 5px 30px;
}
.profile h5,
.changePassword h5 {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
@media (min-width: 1200px) {
  .profile h5,
  .changePassword h5 {
    font-size: 16px;
  }
}

.profile small,
.changePassword small {
  font-size: 12px;
  font-weight: 500;
}
.profile p,
.changePassword p {
  font-size: 16px;
  margin-bottom: 0;
}
.profile span,
.notification span,
.invoices span {
  font-size: 14px;
}
.profileFormButtons {
  width: 100%;
}
.profileFormButtons button {
  width: 50%;
}
@media (min-width: 1200px) {
  .profileFormButtons {
    width: fit-content;
  }
}

.changePassword a {
  font-size: 14px;
  text-decoration: none;
}

.changePassword .formItem {
  width: 100%;
  margin-top: 1rem;
}
@media (min-width: 1200px) {
  .changePassword .formItem {
    width: calc(8 / 12 * 100%);
  }
}

@media (min-width: 1400px) {
  .invoices table > :not(caption) > * > * {
    padding: 0.7rem 1rem;
  }
  .invoices td {
    font-size: 14px;
  }
}

.invoices .date {
  font-size: 16px;
  font-weight: 600;
}
.invoices .amount {
  font-weight: 600;
}

.subscription label {
  font-size: 16px;
}
.text-subscription-2 {
  font-size: 16px;
}
.subscription h4 {
  font-size: 16px;
}
@media (min-width: 1200px) {
  .subscription label {
    font-size: 18px;
    font-weight: 500;
  }

  .text-subscription-2 {
    font-size: 18px;
  }
  .subscription h4 {
    font-size: 24px;
  }

  .subscription .profileFormButtons {
    white-space: nowrap;
  }
}

.stripeInput {
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  height: 38px;
}
h4.fs-24 {
  font-size: 24px;
}

.sideMenu {
  position: fixed;
  width: 270px;
  top: 0;
  overflow: auto;
  z-index: 9;
  border-color: #f0f0f3;
}
.mainElement *,
.sideMenu * {
  font-family: "Raleway";
}

.mainElement i,
.sideMenu i {
  font-family: "Font Awesome 5 Free";
}
@media (min-width: 1200px) {
  .mainElement {
    width: calc(100% - 270px - 335px);
    margin-left: 270px;
    min-height: fit-content;
  }
}
.aside {
  width: 335px;
  min-height: 100vh;
  height: fit-content;
  border-color: #f0f0f3;
}

.tabs {
  border-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: space-between;
  font-weight: bold;
}
.tabs li {
  padding: 0;
}

@media (max-width: 1200px) {
  .tabs li.tabSelected {
    color: #3a77f3;
    padding: 0;
    position: relative;
  }
  .tabs li.tabSelected::after {
    content: "";
    position: absolute;
    width: 10px;
    display: block;
    height: 1px;
    background-color: #3a77f3;
    bottom: -5px;
    left: calc(50% - 5px);
  }
}
@media (min-width: 1200px) {
  .tabs li {
    background-color: #dce4f5;
    border-radius: 10px;
    width: calc(25% - 1.5rem);
    margin-top: var(--bs-gutter-y);
    border: 0;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tabs li.tabSelected {
    color: white;
    background-color: #3a77f3;
  }

  .tabs li:not(:first-child) {
    margin-left: calc(1.5rem * 0.5);
  }
  .tabs li:not(:last-child) {
    margin-right: calc(1.5rem * 0.5);
  }
}

.custom_select {
  position: relative;
}

.selected_option {
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
}

.search_input {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  display: none;
}

.options.open {
  display: block;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}
